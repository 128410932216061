//import React from "react";
import { 
  //Link, 
graphql } from "gatsby";

import Blog from './';
export default Blog;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}, 
      filter: {fields: {language: {eq: "en"}}, frontmatter: { date: { ne: null } } }
      ) {
      edges {
        node {
          excerpt
          fields {
            slug
            path
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            meta {
              name
              content
            }
            link {
              rel
              href
              hreflang
            }
            hero
          }
        }
      }
    }
  }
`
